import { watch } from 'fs';
import _ from 'lodash';
import { Controller } from 'react-hook-form';
import Select, { SelectOptions } from '../Select/Select';
import React from 'react';
import ToggleGroup from '../ToggleGroup/ToggleGroup';
import Textarea from '../Textarea/Textarea';

type FormInputProps = {
  name: string;
  form: any;

  maxLength?: number;
  required?: boolean;

  type?: string;
  className?: string;
  placeholder?: string;
  autoComplete?: string;
  inputMode?: string;

  options?: any;
} & SelectOptions;

export const FormInput = (props: FormInputProps) => {
  const {
    name,
    form,
    maxLength,
    required = false,
    options,
    type = 'text',
    className = props.options ? '' : 'form-input',
    ...otherProps
  } = props;

  const {
    register,
    formState: { errors },
    watch,
    control,
  } = form;

  const isLengthError = errors[name]?.type === 'maxLength';
  const isRequiredError = errors[name]?.type === 'required';

  return (
    <div>
      {options && type !== 'toggle-group' && (
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <Select
              options={options}
              className={className}
              {..._.omit(field, 'ref')}
              {...otherProps}
            />
          )}
        />
      )}

      {options && type === 'toggle-group' && (
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <ToggleGroup
              options={options}
              {..._.omit(field, 'ref')}
              {...otherProps}
            />
          )}
        />
      )}

      {!options && type !== 'textarea' && (
        <input
          type={type}
          className={className}
          {...otherProps}
          {...register(name, { maxLength, required })}
        />
      )}

      {!options && type === 'textarea' && (
        <Controller
          name={name}
          control={control}
          rules={{ maxLength, required }}
          render={({ field }) => (
            <Textarea {..._.omit(field, 'ref')} {...otherProps} />
          )}
        />
      )}

      {isLengthError && (
        <div className="form-error">
          {_.size(watch(name))} / {maxLength} characters max
        </div>
      )}

      {isRequiredError && <div className="form-error">Field is required</div>}
    </div>
  );
};
